/* .header {
  background-color: white;
  background-image: none;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
} */

#tagline {
  background-color: white;
  color:black;
}
#imagesection{
  background-color: white;
  color:black;
}

/* vertical mobile display */
@media only screen and (max-width: 600px) {
  h1 {
    font-size: 2.7rem;
  }

  h3 {
    font-size: 1.2rem;
  }
}