.footer {
    background-color: #000;
    color: #fff;
    padding: 20px;
    font-family: 'Indie Flower', cursive;
  }
  
  .footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .footer p {
    margin: 0;
    font-size: 16px;
  }
  
  .footer a {
    color: #fff;
  }
  
  .footer a:hover {
    text-decoration: underline;
  }
  
  /* Font Awesome icons */
  svg.fa-icon {
    margin-left: 5px;
    font-size: 16px;
  }
  
  /* Font */
  @import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');