html {
  font-family: 'Indie Flower', cursive;
  color: black;
  background-color: white;
  --pink: rgb(237, 190, 173);
  --palepink: rgb(244, 223, 207);
  --darkgrey: rgb(94, 96, 97);
  --lightgreen: rgb(241, 242, 214);
  --olivegreen: rgb(108, 123, 104);
  --pearlgrey: rgb(235, 234, 233);
}

body {
/*   border-left: 200px solid white;
  border-right: 200px solid white; */
  margin: 0;
  margin-top: 100px;
  padding: 0;
  @media (min-width: 1024px) {
    border-left: none;
    border-right: none;
  }
}

.wrapper {
  max-width: 960px;
  margin: 0 auto;
}

li,
span {
  font-family: "Lato", sans-serif;
  color: black;
  font-size: 1rem;
}

h1 {
  font-family: "Cookie", cursive;
  font-size: 4rem;
  color: black;

}

h2 {
  font-family: 'Indie Flower', cursive;
  font-size: 2.5rem;
  color: black;
}

h3 {
  font-family: 'Indie Flower', cursive;
  font-size: 1.3rem;
}

a {
  opacity: 0.75;
  text-decoration: none;
  color: black;
  font-weight: bold;
}

a:hover {
  opacity: 1;
  color: var(--pink);
  text-decoration: none;
}

.section {
  padding: 15px 0;
  box-sizing: border-box;
  margin-top: 1.5rem;
}

.section p {
  max-width: 50rem;
  margin: 1rem auto;
  padding: 10px 20px;
  text-align: center;
  line-height: 2;
}

.section h2 {
  text-transform: capitalize;
  text-align: center;
}

#loading,
#notfound {
  height: 100vh;
}

#loading p,
#notfound p {
  font-size: 2rem;
  color: black;
  padding: 1rem;
}

#loading i {
  color: var(--pink);
}

#notfound button {
  border-radius: 20px;
  padding: 15px 60px;
  margin-top: 2rem;
  border: 1px solid black;
  font-size: 1em;
  letter-spacing: 2px;
  color: black;
  background-color: white;
  cursor: pointer;
  outline: none;
}


/* vertical mobile display */
@media only screen and (max-width: 600px) {
  h1 {
    font-size: 2rem;
  }

  h3,h4 {
    font-size: 1rem;
  }
  body{
  border-left: none;
  border-right: none;
  }
}
